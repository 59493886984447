import React, {Component} from "react";

class HowItWork extends Component {
    render() {
        return (
            <div className="container content-space-1 content-space-lg-3">
                <div className="w-lg-65 text-center mx-lg-auto mb-5 mb-sm-7 mb-lg-10">
                    <h2>How it works</h2>
                    <p>Read below the instructions on how to use this platform</p>
                </div>

                <div className="w-lg-75 mx-lg-auto">
                    <ul className="list-unstyled list-timeline list-py-3">
                        <li className="list-timeline-item">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-file-earmark-check text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title"><span
                                                className="fs-2 text-primary">01.</span> Click on Pay Now</h4>
                                            <p className="card-text">To start of our payment application process, click on the pay now from the button above or from the navigation bar</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="list-timeline-item ms-auto">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-clock-history text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title"><span
                                                className="fs-2 text-primary">02.</span> On the payment page</h4>
                                            <p className="card-text">Select the Tuition or Other Fees tab to select the item</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="list-timeline-item">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-pencil-square text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title"><span
                                                className="fs-2 text-primary">03.</span> Tuition</h4>
                                            <p className="card-text">Select the tuition item you what to pay for. For instance, under tuition section, you can select tuition for one semester or session</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="list-timeline-item ms-auto">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-hand-thumbs-up text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title"><span
                                                className="fs-2 text-primary">04.</span> Other fees</h4>
                                            <p className="card-text">Under other fees, you can select payment for Hostel, Feeding, ID Card, Laundry, etc.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="list-timeline-item">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-pencil-square text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title"><span
                                                className="fs-2 text-primary">05.</span> Finally</h4>
                                            <p className="card-text">Enter the amount you want to pay and click "Validate Payment Button" </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="list-timeline-item ms-auto">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-hand-thumbs-up text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title"><span
                                                className="fs-2 text-primary">06.</span> Other fees</h4>
                                            <p className="card-text">Click on pay now button which will pop up a Paystack payment gateway. You can make payment using Card, Bank, or USSD options.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}
export default HowItWork;
