import React, {Component} from "react";
import Header from "../header/header";
import {connect} from "react-redux";
import axios from "axios";
import {projectTitle, serverLink} from "../../resources/url";
import {Link} from "react-router-dom";
import HowItWork from "./howitwork";
import {
    setCourseFeesData,
    setMinAmountAllowData,
    setOtherFeesData, setPaymentHistoryData, setScholarshipData,
    setWalletData
} from "../../actions/setpersonaldetail";

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentWallet: 0,
            isLoading: true,
            token: this.props.userData.token
        }
    }

    componentDidMount() {
        this.getPaymentData();
    }

    async getPaymentData () {
        const sendData = {
            student_id: this.props.userData.student_id,
            course_code: this.props.userData.course_code,
            student_level: this.props.userData.student_level,
            student_semester: this.props.userData.student_semester,
            app_type: this.props.userData.app_type
        }

        await axios.post(`${serverLink}payment/dashboard`, sendData, this.state.token)
            .then(res => {
                if (res.data.message === 'success') {
                    const wallet = res.data.wallet;
                    this.setState({currentWallet: parseInt(wallet)});
                    this.props.setOnWalletDataDetails(parseInt(wallet));
                    this.props.setOnCourseFeeDataDetails(res.data.course_fee);
                    this.props.setOnOtherFeesDataDetails(res.data.other_fee);
                    this.props.setOnScholarshipDataDetails(res.data.scholarship);
                    this.props.setOnPaymentHistoryDataDetails(res.data.payment_history);
                    this.setState({isLoading: false})
                }
            })
            .catch(error => {
                console.log('ERROR FETCHING RECORD', error)
            })


    }

    currencyConverter = (amount) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'NGN',
        });
        return formatter.format(amount)
    }

    render() {
        return (
            <div>
                <Header/>

                {this.state.isLoading
                    ?
                    <div className="js-swiper-preloader swiper-preloader">
                        <div className="spinner-border text-secondary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    :
                    <main id="content" role="main">
                        <div className="container content-space-t-3 content-space-t-lg-4">
                            <div className="text-center mb-7">
                                <h1 className="display-6">Welcome to {projectTitle} Payment Portal</h1>
                            </div>

                            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                                <div className="col mb-4 mb-lg-0">

                                    <div className="card card-lg text-center h-100">
                                        <div className="card-body">
                                            <div className="mb-3">
                                                <i className="bi-person-circle fs-1 text-dark"/>
                                            </div>

                                            <div className="mb-5">
                                                <h4>{this.props.userData.student_name}</h4>
                                                <h5>
                                                    {
                                                        this.props.userData.app_type === 'student' ?
                                                            this.props.userData.student_id :
                                                            'APP ID: '+this.props.userData.student_id
                                                    }
                                                </h5>
                                            </div>

                                            <div className="mb-5">
                                                <span className="d-block">Course</span>
                                                <span className="d-block"><b>{this.props.userData.course_name.toUpperCase()}</b></span>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className="col mb-4 mb-lg-0">

                                    <div className="card card-lg text-center h-100">
                                        <div className="card-body">
                                            <div className="mb-3">
                                                <i className="bi-currency-exchange fs-1 text-dark"/>
                                            </div>

                                            <div className="mb-5">
                                                <h4>{this.state.currentWallet > 0 ? 'Outstanding Balance' : 'Wallet Balance'}</h4>
                                            </div>



                                            <div className="d-grid mb-3" style={{ fontSize: "25px", fontWeight: "bold" }}>
                                                {this.currencyConverter(this.state.currentWallet)}
                                            </div>

                                        </div>
                                    </div>

                                </div>


                                <div className="col">

                                    <div className="card card-lg text-center h-100">
                                        <div className="card-body">
                                            <div className="mb-3">
                                                <i className="bi-currency-exchange fs-1 text-dark"/>
                                            </div>

                                            <div className="mb-5">
                                                <h4>Pay Now</h4>
                                            </div>

                                            <div className="d-grid mb-3">
                                                <Link className="btn btn-white" to="/pay-now">
                                                    <i className="bi-currency-dollar me-2"/> Pay Now
                                                </Link>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                        {/*<HowItWork />*/}
                    </main>
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.loginDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnWalletDataDetails: (p) => {
            dispatch(setWalletData(p));
        },
        setOnCourseFeeDataDetails: (p) => {
            dispatch(setCourseFeesData(p));
        },
        setOnOtherFeesDataDetails: (p) => {
            dispatch(setOtherFeesData(p));
        },
        setOnMinAmountAllowDataDetails: (p) => {
            dispatch(setMinAmountAllowData(p));
        },
        setOnScholarshipDataDetails: (p) => {
            dispatch(setScholarshipData(p));
        },
        setOnPaymentHistoryDataDetails: (p) => {
            dispatch(setPaymentHistoryData(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
