import {Component} from "react";
import PageRoutes from "./routes/routes";
import { connect } from "react-redux";
import Login from "./component/login/login";

class App extends Component {

    render() {
    return (
        this.props.loginDetails.isPaymentLoggedIn ? <PageRoutes /> : <Login />
    );
  }
}

const mapStateToProps = (state) => {
    return {
        loginDetails: state.loginDetails
    };
};

export default connect(mapStateToProps, null)(App);
