import React, {Component} from "react";
import {Link} from "react-router-dom";
import {
    setCourseFeesData,
    setLogInDetails,
    setMinAmountAllowData, setOtherFeesData, setPaymentHistoryData, setScholarshipData,
    setWalletData
} from "../../actions/setpersonaldetail";
import {connect} from "react-redux";
import {projectLogo, projectTitle} from "../../resources/url";

class Header extends Component {
    logout = () => {
        const loginDetails = {
            message: '', student_name: '', student_id: '', student_email: '', course_id: '',
            app_type: '', student_level: '', student_semester: '', isPaymentLoggedIn: false
        }
        this.props.setOnLogInDetails(loginDetails);


        this.props.setOnCourseFeeData("")
        this.props.setOnWalletData("")
        this.props.setOnMinAmountAllowData("")
        this.props.setOnPaymentHistoryData("")
        this.props.setOnScholarshipData("")
        this.props.setOnOtherFeesData("")
    }

    render() {
        return (
            <header id="header"
                    className="navbar navbar-expand-lg navbar-end navbar-light navbar-absolute-top navbar-show-hide"
                    data-hs-header-options='{
                        "fixMoment": 0,
                        "fixEffect": "slide"
                      }'>
                <div className="container">
                    <nav className="js-mega-menu navbar-nav-wrap">
                        <Link className="navbar-brand" to="/">
                            <img className="navbar-brand-logo" src={projectLogo}
                                 alt={projectTitle}/>
                        </Link>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-default">
                                <i className="bi-list"/>
                            </span>
                            <span className="navbar-toggler-toggled">
                                <i className="bi-x"/>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarNavDropdown">
                            <div className="navbar-absolute-top-scroller">
                                <ul className="navbar-nav nav-pills">
                                    <li className="nav-item">
                                        <Link className="btn btn-ghost-dark me-2 me-lg-0" to="/dashboard">
                                            Dashboard
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="btn btn-ghost-dark me-2 me-lg-0" to="/pay-now">
                                            Pay Now
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="btn btn-ghost-dark me-2 me-lg-0" to="/history">
                                            Payment History
                                        </Link>
                                    </li>


                                    <li className="nav-item ms-lg-auto">
                                        <button className="btn btn-ghost-dark me-2 me-lg-0"
                                                onClick={this.logout}>Logout
                                        </button>
                                    </li>


                                </ul>
                            </div>
                        </div>

                    </nav>
                </div>
            </header>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setOnLogInDetails: (p) => {
            dispatch(setLogInDetails(p));
        },
        setOnWalletData: (p) => {
            dispatch(setWalletData(p));
        },
        setOnCourseFeeData: (p) => {
            dispatch(setCourseFeesData(p));
        },
        setOnMinAmountAllowData: (p) => {
            dispatch(setMinAmountAllowData(p));
        },
        setOnPaymentHistoryData: (p) => {
            dispatch(setPaymentHistoryData(p));
        },
        setOnScholarshipData: (p) => {
            dispatch(setScholarshipData(p));
        },
        setOnOtherFeesData: (p) => {
            dispatch(setOtherFeesData(p));
        },
    };
};
export default connect(null, mapDispatchToProps)(Header);
